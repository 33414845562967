// core version + navigation, pagination, thumbs modules:
import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Scrollbar]);

$( window ).on("load", function() {
    var rmltSlider = new Swiper('.rmltSlider', {
        loop: false,
        speed: 1400,
        clickable: true,
        slidesPerView: "auto",
        spaceBetween: 16,
        scrollbar: {
            el: ".swiper-scrollbar",
            hide: false,
        },
        navigation: {
            nextEl: '.rmltSlider__nav--next',
            prevEl: '.rmltSlider__nav--prev',
        },
        on: {
            init: function () {

            },
        },
        breakpoints: {
            300: {
                slidesPerView: 1,
                spaceBetween: 16,
            },
            1024: {
                slidesPerView: "auto",
                spaceBetween: 16,
            },
        }
    });
});